.navbar {
  height: 90px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 1.2rem !important;
  position: fixed !important;
  flex-wrap: wrap !important;
  width: 100%;
  top: 0;
  transition: background-color 0.3s ease;
  background-color: transparent; 
  z-index: 650;
  
}
.navbar.scrolled {
  background-color: #e7f2fd; 
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100;
/* margin-bottom: 50px; */
}

.nav-container a img {
  height: 60px;
  margin-left: 0px;
  align-items: center;
  cursor: pointer;
}

.navbar-logo {
  height: 60px;
}

.navbar-app {
  height: 100px;
}

@media (max-width: 600px) {
  .navbar-logo {
      height: 40px;
  }
  
  .navbar-app {
    height: 70px;
  }
}





/*ABC*/

.nav-menu {
  display: flex;
  justify-content: center !important;
  margin-top: 3px;
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-links {
  color: #9c9aa0;
  text-decoration: none;
  font-size: 17px;
  font-weight: 500;
  padding: 0.5rem 1rem;
  /* height: 100%; */
  border-bottom: 3px solid transparent;


}

.nav-links:hover {
  color: #086ad8;
}

/* .fa-code {
    margin-left: 1rem;
  } */

.nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* line-height: 40px; */
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  justify-content: center;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  background: #086ad8;
}

/* .nav-item .active {
    color: #ffdd40;
    border: 1px solid #ffdd40;
  } */

.nav-icon {
  display: none;
  color: #086ad8;
}

.nav-icon .icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

@media screen and (max-width: 960px) {
  .nav-container a img {
    height: 60px;
    margin-left: 10px;
    align-items: center;
    cursor: pointer;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 120px;
    left: -110%;
    opacity: 1;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
  }
  .get-a-quote {
    display: flex;
    margin-top: 10px;
    width: 150px !important;
    height: 45px;
    border-radius: 50px;
    margin-left: 50px;
    background-color: #ffffff;
    align-items: center;
    justify-content: center;
    box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
    margin-right: 70px;
    margin-bottom: 20px;
  }

  .nav-menu.active {
    background: #E9F3FD;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item .active {
    color: #9c9aa0;
    border: none;
  }
  
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }
  .nav-links:hover {
    color: #086ad8;
    padding: 1.5rem;
    width: 100%;
    display: table;
  }
  .nav-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-100%, -50%);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: #086ad8;
  }
}

/* @media only screen and (min-width: 768px) and (max-width: 991px) {
    .get-a-quote {
      margin: 30px 15px !important;
      border-radius: 50px;
    } }
  @media only screen and (max-width: 767px) {
    .get-a-quote {
      margin: 30px 15px !important;
      border-radius: 50px;
     } } */

.get-a-quote {
  display: flex;
  height: 45px;
  border-radius: 50px;
  /* margin-top: 6px; */
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
  margin-right: 70px;
}

.get-a-quote a {
  color: #086ad8 !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 20px !important;
  border-radius: 50px;
  height: 45px;
  font-weight: 700 !important;
}

/* .get-a-quote a:hover {
        color: white !important;
      } */

.get-a-quote:hover,
.get-a-quote a:hover {
  box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
  border-radius: 50px;
  background: #086ad8;
  color: #ffffff !important;
  text-decoration: #D7D3E4;
}





.single-welcome-slide {
  position: absolute;
  
  width: 100%;
  
  background-color: #ffffff;
}


.single-welcome-slide .background-curve {
  position: absolute;
  width: 70%;
  top: 0;
  height: 100%;
  right: 0;
  z-index: -1;
}

.single-welcome-slide .background-curve img {
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
}

