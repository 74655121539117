.home {
    height: 100vh;
    gap: 80px;
    display: flex;
    flex-wrap: wrap;
}

.home-left {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: 50%;
    line-height: 1.1;
}

.home-left h2 {
    color: #1e212d;
    font-size: 16px;
    font-weight: 700;
    
}
.home-left h3 {
    color: #090909;
    font-size: 18px;
    margin: 0;
    font-weight: 200;
}
.home-left p {
    color: #1583E9;
    font-size: 60px;
    font-weight: 700;
}

.home-latest-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 230px;
    height: 50px;
    border-radius: 75px;
    margin-top: 30px;
    background: #6091ef;
    color: white;
    font-size: 22px;
    font-weight: 500;
}

.home-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-right img {
    max-width: 120%;
    height: auto;
}


@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {

    .home {
        height: 100vh;
        padding-top: 100px;
        margin-bottom: 30px;
        gap: 20px;
        
        display: flex;
        flex-wrap: wrap;
    }
    .home-left {
        width: 100%;
    }

    .home-left h2 {
        color: #090909;
        font-size: 16px;
        margin: 0;
        font-weight: 400;
        
    }
    .home-left h3 {
        color: #090909;
        font-size: 16px;
        margin: 0;
        font-weight: 200;
        
    }
    .home-left p {
        color: #171717;
        font-size: 30px;
        font-weight: 700;
    }

    .home-right img {
        max-width: 100% !important;
        height: auto;
    }

    .home-latest-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        width: 180px;
        height: 40px;
        border-radius: 75px;
        margin-top: 15x;
        background: #6091ef;
        color: white;
        font-size: 18px;
        font-weight: 500;
    }
}