.industries-title {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
}

.industries-title p {
    text-align: center;
    color: #171717;
    font-size: 40px;
    font-weight: 500;
    gap: 0;
    line-height: 40px;
}




.industries_items{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}
.industries_single_items{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 17% !important;
    padding: 20px 5px;
    background-color: #fff;
    border: 1px solid #fff;
    box-shadow: 0 10px 35px rgb(10 10 10 / 7%);
    border-radius: 12px;
    transition: .3s;
    
}
.portfolio-bg-curve {
    width: 100%;
    display: flex;
    z-index: -1;
    position: absolute;
    justify-content: end;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
    .portfolio-bg-curve {
        width: 50%;
        height: auto;
        display: flex;
        z-index: -1;
        position: absolute;
        justify-content: end;
    }
    .industries_single_items{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 40% !important;
        padding: 20px 10px;
        background-color: #fff;
        border: 1px solid #fff;
        box-shadow: 0 10px 35px rgb(10 10 10 / 7%);
        border-radius: 12px;
        transition: .3s;
        
    }

    .industries_single_items .industries_item_title h5{
        font-weight: 600;
        margin-bottom: 12px;
        font-size: 14px;
        display: block;
    }
}

.industries_single_items .industries_icon{
    width: 60px;
    height: 60px;
    /* background-color: #086ad8; */
    border-radius: 5px;
    margin-bottom: 15px;
    /* box-shadow: 0 4px 6px #0000002a; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.industries_icon img{
    width: 35px;
    height: 35px;
    text-align: center;
}
.industries_single_items .industries_item_title h5{
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 12px;
    text-align: center;
    display: block;
}
.industries_single_items .industries_item_title p{
    color: #7e7e7e;
    font-size: 15px;
    line-height: 25px;
}
.industries_single_items:hover{
    box-shadow: 0 5px 8px #00000017;
    border: 1px solid #086ad8;
    transform: translateY(-5px);
}