.section-space--ptb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.section-space--mb_60 {
    margin-bottom: 60px;
}

.text-center {
    text-align: center !important;
}

.text-color-primary {
    color: #086ad8;
}

.section-title-wrap h3 {
    font-size: 35px;
}

.ht-gradation.style-01 {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
  }
  .ht-gradation.style-01 .item {
    flex-grow: 1;
    position: relative;
    padding: 0 15px;
  }
  .ht-gradation.style-01 .item .line {
    width: 100%;
    height: 1px;
    background: #ededed;
    position: absolute;
    top: 24px;
    left: 15px;
  }
  .ht-gradation.style-01 .item:last-child .line {
    display: none;
  }
  .ht-gradation.style-01 .item .mask {
    opacity: 0;
    visibility: hidden;
  }
  .ht-gradation.style-01 .item:hover .mask {
    opacity: 1;
    visibility: visible;
  }
  .ht-gradation.style-01 .item:hover .circle {
    color: #ffffff;
    background: #086AD8;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
    .ht-gradation.style-01 {
      display: block;
    }
    .ht-gradation.style-01 .item {
      display: flex;
      align-items: flex-start;
      width: 100%;
    }
    .ht-gradation.style-01 .item .line {
      left: 39px;
      height: 100%;
      width: 1px;
      top: 15px;
    }
    /* .ht-gradation.style-01 .item .line {
        width: 100%;
        height: 1px;
        background: #ededed;
        position: absolute;
        top: 24px;
        left: 15px;
    } */
    .ht-gradation.style-01 .content-wrap {
      padding: 10px 0 50px 30px;
    }
  }
  .ht-gradation.style-01 .circle-wrap {
    display: inline-block;
    position: relative;
    margin-bottom: 56px;
  }
  .ht-gradation.style-01 .circle {
    position: relative;
    z-index: 1;
    width: 48px;
    height: 48px;
    line-height: 44px;
    text-align: center;
    border: 2px solid #e8e8e8;
    border-radius: 50%;
    font-size: 18px;
    background-color: #fff;
    margin-bottom: 0;
    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ht-gradation.style-01 .wave-pulse {
    width: 1px;
    height: 0;
    margin: 0 auto;
  }
  .ht-gradation.style-01 .wave-pulse::before, .ht-gradation.style-01 .wave-pulse::after {
    opacity: 0;
    content: "";
    display: block;
    position: absolute;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #ababab;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-animation: gradationMask 3s linear infinite;
    animation: gradationMask 3s linear infinite;
  }
  .ht-gradation.style-01 .wave-pulse-2::before {
    animation-delay: 1.2s;
  }
  .ht-gradation.style-01 .wave-pulse-3::before {
    animation-delay: 2.4s;
  }
  .ht-gradation.style-01 .heading {
    font-weight: 500;
  }
  .ht-gradation.style-01 .text {
    margin-top: 17px;
  }
  .ht-gradation.style-01 .gradation-btn {
    font-weight: 500;
    margin-top: 20px;
    font-size: 14px;
    color: #086ad8;
  }
  .ht-gradation.style-01 .gradation-btn:hover {
    color: #d2a98e;
  }
  
  @-webkit-keyframes gradationMask {
    0% {
      -webkit-transform: translate(-50%, -50%) scale(0);
      transform: translate(-50%, -50%) scale(0);
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1);
      opacity: 0;
      border-color: transparent;
    }
  }
  @keyframes gradationMask {
    0% {
      -webkit-transform: translate(-50%, -50%) scale(0);
      transform: translate(-50%, -50%) scale(0);
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1);
      opacity: 0;
      border-color: transparent;
    }
  }
  