.breadcrumb-area {
    padding-top: 114px;
    padding-bottom: 114px;
    background-color: #f6f2ed70;
    background-repeat: no-repeat;
    background-position: center center;
    border-bottom-width: 0px;
    padding-top: 136px;
    padding-bottom: 131px;
    background-size: cover;
    z-index: 3;
    position: relative;
    /* background-image: url(../images/bg/title-bar-01-bg.webp); */
  }
  .breadcrumb_box {
    display: flex;
    flex-direction: column;
  }
  @media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .breadcrumb-area {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

  .site-wrapper-reveal {
    position: relative;
    z-index: 2;
    background: #ffffff48;
}

/* Contact us form */

/*======================================
=         33. Contact Us Css            =
=======================================*/

.contact-inner input, .contact-inner textarea {
    width: 100%;
    color: #000;
    border-color: #f8f8f8;
    background-color: #f8f8f8;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0em;
    border: none;
    width: 100%;
    outline: none;
    border: 1px solid #eee;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: 5px;
    padding: 3px 20px;
    height: 50px;
}

.contact-form__two {
    input[type="text"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="number"]:focus, input[type="tel"]:focus, input[type="range"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="time"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="color"]:focus, textarea:focus, select:focus, select:focus, textarea:focus {
        color: #222;
        border-color: transparent;
        background: #fff;
        box-shadow: 0 0 40px rgba(51,51,51,.1);
    }
    select {
        &:focus {
            /* background: #fff  20px !important; */
        }
    }
}

.appointment-contact-bg {
    /* background: url(../images/bg/home-appointment-contact-bg-image.webp); */
    background-repeat: no-repeat;
    background-position: center right;
}

.service-contact-bg {
    background-color: #002fa6;
    /* background-image: url(../images/bg/mitech-home-services-contact-bg.webp); */
    background-repeat: no-repeat;
    background-position: left bottom;
}
.processing-contact-us-bg {
    background-color: #002fa6;
    /* background-image: url(../images/bg/mitech-processing-contact-bg.webp); */
    background-repeat: no-repeat;
    background-position: top right;
}
.contact-title {
    & .sub-title {
        font-size: 18px;
    }
}
.contact-input {
    margin: 0 -10px;
    .contact-inner {
        float: left;
        margin: 0 0 20px;
        padding: 0 10px;
        width: 50%;
        @media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
            width: 100%;
        }
    }
}
.contact-inner {
    margin: 0 0 20px;
    & input , textarea {
        width: 100%;
        color: #000;
        border-color: #f8f8f8;
        background-color: #f8f8f8;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0em;
        border: none;
        width: 100%;
        outline: none;
        border: 1px solid #eee;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color:transparent;
        border-radius: 5px;
        padding: 3px 20px;
        height: 50px;
    }
    &  textarea {
        height: 150px;
        padding: 15px;
    }
    & select {
        color: #777;
    }
}

.infotechno-contact-us-bg {
    background-color: #eff2f6;
    /* background-image: url(../images/bg/home-infotechno-contact-bg.webp); */
    background-size: cover;
    background-repeat: no-repeat;
} 
.conact-us-wrap-one {
    @media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
        margin-bottom: 30px;
    }
    .heading {
        position: relative;
        padding-left: 34px;
        font-weight: 600;
        line-height: 1.4;
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 4px;
            height: 94%;
            /* -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%); */
            transform: translateY(-50%);
            background-color: black;
        }
    }
    & .sub-heading {
        margin-top: 15px;
        font-size: 18px;
        margin-left: 34px;
        color: #696969;
    }
}
.conact-us-wrap-three {
    @media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
        margin-bottom: 30px;
    }
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 4px;
        height: 94%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        background-color: black;
    }
    .section-sub-title {
        padding-left: 34px;
    }
    .heading {
        position: relative;
        padding-left: 34px;
        font-weight: 600;
        line-height: 1.4;
        
    }
    & .sub-heading {
        margin-top: 15px;
        font-size: 18px;
        margin-left: 34px;
        color: #696969;
    }
}
.contact-info-one, .contact-info-two, .contact-info-three {
    & .icon { 
        font-size: 40px;
        color: #086ad8;
    }
    & .heading {
        font-size: 15px;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    & .call-us {
        line-height: 1.17;
        color: #002fa6;
        position: relative;
        display: inline-block;
        &::before {
            content: '';
            width: 0;
            height: 1px;
            bottom: 0;
            position: absolute;
            left: auto;
            right: 0;
            z-index: 1;
            /* -webkit-transition: width .6s cubic-bezier(.25,.8,.25,1) 0s; */
            transition: width .6s cubic-bezier(.25,.8,.25,1) 0s;
            background: currentColor;
        }
        &:hover {
            color: #002fa6;
            &::before {
                width: 100%;
                left: 0;
                right: auto;
            }
        }
        & a {
            &:hover {
                color: #002fa6;
            }
        }
    }
    & .btn--secondary {
        min-width: 230px;
        &:hover {
            background: yellow;
        }
    }
}

.contact-info-two {
    & .icon { 
        color: #fff;
    }
    & .heading {
        color: rgba(255,255,255,0.8);
    }
    & .call-us {
        color: black;
        &:hover {
            color: pink;
        }
        & a {
            &:hover {
                color: pink;
            }
        }
    }
    & .btn--secondary {
        
        color: black;
        &:hover {
        
            color: white;
        }
    }
}

.contact-info-three {
    @media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
        margin-top: 60px;
    }
    & .heading {
        margin-bottom: 30px;
    }
    & .call-us {
        margin-bottom: 20px;
    }
    & .location-text-button {
        .button-icon {
            height: 56px;
            width: 56px;
            line-height: 56px;
            border-radius: 50%;
            text-align: center;
            background: #f6f2ed;
            margin-right: 14px;
            display: inline-block;
            &::before {
                content: '\f3c5';
                font-style: normal;
                line-height: 56px;
                text-align: center;
                color: blueviolet;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                /* font-family: $font-awesome; */
                font-weight: 900;
            }
        }
        .button-text {
            font-weight: 500;
            color: black;
            position: relative;
            &::after {
                content: '';
                width: 0;
                height: 1px;
                bottom: 0;
                position: absolute;
                left: auto;
                right: 0;
                -webkit-transition: width .6s cubic-bezier(.25,.8,.25,1) 0s;
                transition: width .6s cubic-bezier(.25,.8,.25,1) 0s;
                background: currentColor;
            }
        }
        &:hover {
            & .button-text {
                &::after {
                    width: 100%;
                    left: 0;
                    right: auto;
                }
            }
        }
    }
    
}
.processing-computing-area {
    padding: 150px 0;
    @media  only screen and (min-width: 992px) and (max-width: 1199px) {
       padding: 100px 0;
    }
    @media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px){
        padding: 60px 0;
    }
}

.computing-info-box {
    & h2 {
        font-size: 64px;
        line-height: 1.13;
        color: #002fa6;
        @media  only screen and (min-width: 992px) and (max-width: 1199px) {
            font-size: 42px;
        }
        @media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px){
            font-size: 32px;
        }
    }
}
.contact-form-service-wrap {
    background: #fff;
    box-shadow: 0 2px 30px rgba(0,0,0,.1);
    padding: 42px 50px 50px;
    border-radius: 5px;
    @media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
        padding: 42px 20px 50px;
    }
}
.business-solution-form-wrap {
    max-width: 400px;
    margin-left: auto;
    background: #fff;
    box-shadow: 0 2px 30px rgba(0,0,0,.1);
    padding: 42px 30px 51px;
    border-radius: 5px;
    text-align: center;
    @media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px){
       margin: auto;
    }
}
.ht-star-rating {
    &.lg-style {
        color: #fb0;
        font-size: 24px;
        & span + span {
            margin-left: 11px;
        }
    }
}
.contact-info{
    @media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px){
       margin-bottom: 60px;
    }
    &.sytle-one,
    &.style-two {
        @media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px){
            margin-top: 60px;
            &.service-contact {
                margin-top: 0;
            }
        }
        .contact-info-title-wrap {
            & .sub-text {
                margin-top: 20px;
                color: white;
                font-size: 18px;
            }
        }

        .contact-list-item {
            & .single-contact-list {
                display: block;
            }
            & .content  {
                display: flex;
                margin-top: 55px;
                &  .icon {
                    color: #fff;
                    border-color: #fff;
                    font-size: 40px;
                    margin-right: 10px;
                    flex-shrink: 0;
                    min-width: 54px;
                }
                .main-content {
                    .heading {
                        font-size: 15px;
                        font-weight: 500;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                        line-height: 1.78;
                        margin-bottom: 13px;
                        color: rgba(255,255,255,0.8);
                    }
                    .text {
                        color: gray;
                        font-size: 34px;
                        font-weight: 700;
                        line-height: 1.17;
                        @media  only screen and (min-width: 992px) and (max-width: 1199px) {
                           font-size: 24px;
                        }
                        @media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px){
                           font-size: 24px;
                        }
                    }
                }
            }
        }
    }
    &.style-two{
        .contact-info-title-wrap {
            & .sub-text {
                color: #696969;
            }
        }
        .contact-list-item {
            & .content  {
                &  .icon {
                    color: green;
                }
                .main-content {
                    .heading {
                        color: #333333;
                    }
                    .text {
                        color: blue;
                    }
                }
            }
        }
    }
}

.conact-info__list {
    & li {
        margin-bottom: 7px;
    }
}
.conact-us-wrap-one.managed-it .heading {
    line-height: 1.67;
    font-weight: 800;
}

.section-space--pt_100 {
    padding-top: 100px;
}
.section-space--pb_100 {
    padding-bottom: 100px;
}
.section-space--pb_70 {
    padding-bottom: 70px;
}

.mt-20 {
    margin-top: 20px;
}
.mt-30 {
    margin-top: 30px;
}
.mb-20 {
    margin-bottom: 20px;
}


.ht-btn.ht-btn-md {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0em;
    line-height: 54px;
    height: 54px;
}

.ht-btn {
    font-size: 15px;
    font-weight: 500;
    border-style: solid;
    border-radius: 5px;
    border-color: transparent;
    padding: 0 36px;
    text-align: center;
    color: #ffffff;
    background: #086ad8;
    border-width: 1px;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
}

.ht-btn:hover {
    background-color: white;
    color: #000;
    box-shadow: 0 4px 6px #0000002a;
}

.conact-us-wrap-one .heading {
    position: relative;
    font-size: 38px;
    padding-left: 34px;
    font-weight: 600;
    line-height: 1.4;
}

.conact-us-wrap-one .sub-heading {
    margin-top: 15px;
    font-size: 18px;
    margin-left: 34px;
    color: #696969;
}
.conact-info-wrap .heading {
    font-size: 22px;
    font-weight: 700;
}

.homeparagraph a {
    text-decoration: none;
    font-size: 16px;
    text-align: center;
    color: #6E6E6E;
}
.homeparagraph a:hover{
    /* -webkit-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1); */
    /* -o-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1); */
    color: #086ad8 !important;
}
.hover-style-link:hover {
    /* -webkit-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1); */
    /* -o-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1); */
    color: #086ad8;
}
ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
.homeparagraph {
    text-decoration: none;
    font-size: 16px;
    text-align: center;
    color: #6E6E6E;
}
.font-weight--bold {
    font-weight: 800;
}

.cta-bg-image_two {
    background-color: #E7F2FD;
    /* background-image: url(../images/patterns/mitech-home-appointment-call-to-action-bg-image.webp); */
    background-repeat: no-repeat;
    background-position: top right;
}
.section-space--ptb_80 {
    padding-top: 80px;
    padding-bottom: 80px;
}
.resources-text {
    font-size: 25px;
    color: #300f14;
}

.text-center {
    text-align: center !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cta-button-group--two .btn-one {
    background: #086ad8;
    color: #ffffff;
    margin: 0px;
    text-align: center;
    
}
.cta-button-group--two .btn-one:hover {
    background: #ffff;
    color: black;
    margin-bottom: 5px;
    text-align: center;
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.07);
}

/* .btn--white {
    background-image: none;
    background-color: #ffffff;
    color: #000000;
    -webkit-box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.07);
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.07);
} */

.btnc {
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    border-style: solid;
    border-radius: 5px;
    border-color: transparent;
    border: 1px solid transparent;
    padding: 0 36px;
    text-align: center;
    
    transition: all 0.4s ease-in-out;
    height: 54px;
    line-height: 54px;
    letter-spacing: 0em;
    color: #ffffff;
    background: #086ad8;
    
    transform: translateY(0px);
}

.cta-button-group--two .btn-two {
    /* margin: 5px; */
}
.btn--secondary {
    background-image: none;
    color: white;
    margin-left: 10px;
    background-color: black;
}
.btn--secondary:hover {
    background-color: white;
    color: black;
    margin-bottom: 5px;
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.07);
}
.me-2 {
    margin-right: .5rem !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-50%, -60%);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
    to {
        opacity: 0;
        transform: translate(-50%, -60%);
    }
}

.success-dialogue {
    position: fixed;
    top: 50%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1000;
    text-align: center;
    animation: fadeIn 0.5s forwards;
}

.success-dialogue.fade-out {
    animation: fadeOut 0.5s forwards;
}

.success-dialogue p {
    margin: 0;
    font-size: 22px;
    font-weight: 600;
    color: #333;
}