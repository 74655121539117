.hire {
    height: 100%;
    margin-top: 100px;
    margin-bottom: 100px;
}

.hire-us {
    display: flex;
    
    flex-direction: row;
    justify-content: center;
    /* align-items: center; */
}

.hire-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    animation: fadInLeft 0.2 ;
}

.hire-left  h6 {
    font-size: 16px;
    color: #b4b2b5;
    font-weight: 700;
    letter-spacing: 2px;
}

.hire-left h2 {
    font-weight: 600;
    font-size: 38px;
}

.hire-left h2 span{
    color: #086ad8;
}

.hire-left p {
    
        font-size: 18px;
        margin-top: 10px;
        color: var(--theme-black);
        opacity: 0.8;
        line-height: 1.7;
    
}

.quotes {
    margin-top: 40px;
}
.quotes .item {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    padding: 5px 10px;
    border-radius: 22px;
}

.quotes .item .icon {
    height: 50px;
    width: 50px;
    background: linear-gradient(239deg, rgba(2, 0, 36, 1) 0%, rgba(23, 77, 255, 1) 0%, rgba(1, 76, 161, 1) 51%);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.quotes-img {
    width: 50px;
}

.quotes .item .quotes-det h5 {
    font-size: 22px;
    font-weight: 600;
    padding-left: 15px;
}

.quotes .item .quotes-det p {
    color: black;
    opacity: 0.8;
    font-size: 17px;
    margin-top: 10px;
    padding-left: 15px;
}



.home-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-right img {
    max-width: 100%;
    height: auto;
}